import { toTheme } from '@theme-ui/typography'
import bootstrapTypographyTheme from 'typography-theme-bootstrap'
const typographyTheme = toTheme(bootstrapTypographyTheme)

const rhythm = typographyTheme.typography.rhythm

const mdxElementsWithTextColor = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
mdxElementsWithTextColor.forEach(h => {
  typographyTheme.styles[h].fontWeight = 'bold'
  typographyTheme.styles[h].marginBottom = rhythm(1)
  typographyTheme.styles[h][':not(:first-of-type)'] = { marginTop: rhythm(2) }
})

const bulmaRgb = {
  link: [50, 115, 220],
  info: [32, 156, 238],
  success: [72, 199, 116],
  warning: [255, 221, 87],
  danger: [255, 56, 96],
}

const boxShadow = '0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)'

const base = {
  contentMaxWidth: 960,
  boxShadow,
  breakpoints: ['40em', '64em'],
  colors: {
    text: '#4a4a4a',
    background: '#fff',
    primary: '#17b4ca',
    secondary: '#30c',
    muted: '#f6f6f6',
    subtitle: 'rgba(255, 255, 255, 0.9)',

    link: 'hsl(217, 71%, 53%)',
    linkBackground: '#eef3fc',
    linkForeground: '#3273dc',

    info: 'hsl(204, 86%, 53%)',
    infoBackground: '#eef6fc',
    infoForeground: '#1d72aa',

    success: 'hsl(141, 53%, 53%)',
    successBackground: '#effaf3',
    successForeground: '#257942',

    warning: 'hsl(48, 100%, 67%)',
    warningBackground: '#fffbeb',
    warningForeground: '#947600',
    warningTooltipText: '#3b3108',

    danger: '#FF3860',
    dangerBackground: '#feecf0',
    dangerForeground: '#cc0f35',

    lightgrey: '#dbdbdb',
    whitesmoke: '#f5f5f5',
  },
  text: {
    color: 'text',
  },
  input: {
    boxShadow: 'inset 0 1px 2px rgba(10, 10, 10, 0.1)',
    borderColor: 'lightgrey',
    '::placeholder': {
      color: 'lightgrey',
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: boxShadow, //'0 0 8px rgba(0, 0, 0, 0.125)',
    },
    recentPost: {
      padding: 0,
      boxShadow: boxShadow, // '0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)',
    },
  },
  bulmaRgb,
  ...typographyTheme,
  // for MDX
  styles: {
    ...typographyTheme.styles,
    '*': {
      color: 'text',
    },
    li: {
      ':not(:last-child)': {
        mb: 1,
      },
      ul: {
        mb: 0,
      },
    },
    a: {
      textDecoration: 'none',
      overflowWrap: 'break-word',
    },
    table: {
      borderCollapse: 'collapse',
      borderSpacing: '0',
      mb: 3,
      border: 'none',
      td: {
        border: 'none',
      },
      'tr:not(:last-child) > td': {
        borderBottom: ({ colors }) => `1px solid ${colors.lightgrey}`,
      },
      th: {
        borderBottom: ({ colors }) => `2px solid ${colors.lightgrey}`,
      },
    },
  },
}

export default base
