const React = require('react')
const { DragDropContextProvider } = require('react-dnd')
const MultiBackend = require('react-dnd-multi-backend').default
const Html5ToTouch = require('react-dnd-multi-backend/lib/HTML5toTouch').default
const Global = require('@emotion/core').Global

exports.wrapRootElement = ({ element }) => {
  return (
    <DragDropContextProvider backend={MultiBackend(Html5ToTouch)}>
      {element}
    </DragDropContextProvider>
  )
}
